import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import media from 'utils/media-queries'

import { color, fontSize } from 'styles/theme'

import TwoColumns from 'components/twoColumns'
import SectionHeading from 'components/sectionHeading'

const Big = styled.span`
  font-size: ${fontSize.f6};
  color: ${color.grey900};
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: 1.35;
  ${media.lg`
    font-size: ${fontSize.f5};
    letter-spacing: -0.3px;
  `}
  ${media.sm`
    font-size: ${fontSize.f5};
  `}
`

const About = () => {
  return (
    <TwoColumns
      leftColumn={<SectionHeading>About</SectionHeading>}
      rightColumn={
        <Fragment>
          <Big>Hi. I'm Jessar, a 17 year old cybersecurity researcher living in Oslo.</Big>
          <p>At present, I'm programming and participating in Capture the Flag (CTF) events.</p>
          <p>
            During my spare hours, I am passionate about skateboarding, martial arts, playing the piano, reading, and drawing.
          </p>
          <i style={{ marginBottom: 0, color: 'Gray', fontSize: '1.1rem' }}>Life consists of two days, one for you one against you.<br></br>
            ~ Hazrat Ali (A.S)</i>
        </Fragment>
      }
    />
  )
}

export default About
