import React, { useState } from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'
import { color, fontSize } from 'styles/theme'
import Icon from 'components/icons'
import "./header.css"

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 0 0 auto;
  ${media.lg`
  height: ${props => (props.article ? '64px' : '')};
  `}
  ${media.sm`
    display: ${props => (props.article ? 'flex' : 'block')};
  `};
  z-index: 10;
  height: ${props => (props.article ? '74px' : '')};
  ${media.xs`
    display: block;
    height: 112px;
  `}
`

const LogoWrapper = styled.div`
  padding: 24px 0 24px 24px;
  ${media.sm`
    padding: ${props => (props.article ? '24px 0 24px 24px' : '24px 0 0 0')};
  `};
  ${media.xs`
    padding: 24px 0 0 0;
  `}
`

const NameLink = styled.a`
  text-decoration: none;
`

const Name = styled.h1`
  white-space: nowrap;
  font-size: ${fontSize.f6};
  text-align: left;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.8px;
  ${media.sm`
    text-align: center;
    font-size: ${fontSize.f7};
  `};
`

const NameArticle = styled.div`
  white-space: nowrap;
  font-size: ${fontSize.f5};
  font-weight: 700;
  text-align: left;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: ${color.grey900};
  ${media.xs`
    text-align: center;
  `};
`

const Role = styled.div`
  color: ${color.grey700};
  text-align: left;
  line-height: 1.4;
  font-size: ${fontSize.f4};
  ${media.sm`
    text-align: center;
    font-size: ${fontSize.f6};
  `};
`

const SocialLinks = styled.div`
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: auto auto auto;
  padding: 15px 24px 0 24px;
  ${media.sm`
    padding: ${props => (props.article ? '15px 12px 0 8px' : '4px 0 0 0')};
    grid-column-gap: 0;
  `}
  ${media.xs`
    padding: 0;
  `}
  justify-content: center;
`

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 20px;
  color: ${color.grey900};
  border: 1px solid white;
  &:hover {
    border: 1px solid ${color.grey150};
    background: ${color.grey150};
    color: ${color.grey900};
  }
  &:active {
    color: ${color.grey900};
  }
  &:visited {
    color: ${color.grey900};
  }
`

export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  color: inherit;
  background: inherit;
`

export const InlineSvg = styled.svg`
  height: 24px;
  width: 24px;
  color: inherit;
  fill: currentColor;
`

const Tooltip = styled.div`
  padding: 2px 24px 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 300ms;
  ${media.sm`
    justify-content: center;
    padding-top: 6px;
  `}
`

const TooltipIcon = styled.div`
  transform: rotate(270deg);
  margin-left: 8px;
`

const TooltipText = styled.div``

const Header = props => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)
  const [tooltipText, setTooltipText] = useState('')

  const showTooltip = tooltipText => {
    setTooltipIsVisible(true)
    setTooltipText(tooltipText)
  }

  const hideTooltip = () => {
    setTooltipIsVisible(false)
  }

  return (
    <Div article={props.article}>
      <LogoWrapper article={props.article}>
        {props.article && (
          <NameLink href="/">
            <NameArticle article={props.article}>Jessar Qais</NameArticle>
          </NameLink>
        )}
        {!props.article && <Name article={props.article}><div id="name"><u>Jessar Qais</u><a href="#about">&darr;</a> </div></Name>}
        {!props.article && <Role><div id="subtitle">Cybersecurity</div></Role>}
      </LogoWrapper>
      <div id="mysocials">
        <SocialLinks article={props.article}>
          <SocialLink
            href={`mailto:contact@jessar.dev`}
            aria-label="Send an email to Jessar"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="m22.3592881 5.82427054v.59619016c0 .24378184-.1417446.46531584-.3630886.56747461l-8.9485541 4.13010189c-.6647377.306802-1.4305531.306802-2.0952908 0l-8.94855408-4.13010189c-.22134402-.10215877-.36308864-.32369277-.36308864-.56747461v-.59619016c0-.55012277.44596262-.99608539.9960854-.99608539h18.72640542c.5501228 0 .9960854.44596262.9960854.99608539zm-1.25 13.34754436h-18.21857622c-.69035594 0-1.25-.5596441-1.25-1.25v-8.52329148c0-.20710678.16789322-.375.375-.375.05424403 0 .10784237.01176807.15709707.03449228l8.78345405 4.0523453c.6647826.3067049 1.4305981.306593 2.095291-.0003062l8.7755375-4.05180448c.1880319-.08681729.4108411-.00476666.4976584.18326519.0227541.04928162.0345382.1029156.0345382.1571966v8.52310279c0 .6903559-.559644 1.25-1.25 1.25z" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
          <SocialLink
            href="https://github.com/J3554R"
            target="blank"
            aria-label="Jessar's github profile"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="M11.996 0C9.14719 0.00152411 6.39176 1.04194 4.22245 2.9352C2.05314 4.82847 0.611401 7.45113 0.155032 10.3342C-0.301338 13.2173 0.257416 16.1728 1.73139 18.6723C3.20537 21.1718 5.49845 23.0522 8.20061 23.9774C8.79674 24.0915 9.02129 23.7105 9.02129 23.3866C9.02129 23.0626 9.00937 22.1234 9.00539 21.0966C5.66701 21.8402 4.96159 19.644 4.96159 19.644C4.41711 18.2179 3.63021 17.843 3.63021 17.843C2.54126 17.081 3.71168 17.0953 3.71168 17.0953C4.91787 17.1829 5.55177 18.3645 5.55177 18.3645C6.62085 20.245 8.35957 19.701 9.04315 19.3832C9.15045 18.5866 9.46244 18.0447 9.80621 17.737C7.13948 17.4274 4.33762 16.372 4.33762 11.6576C4.3211 10.435 4.76362 9.2526 5.57362 8.35512C5.45042 8.04544 5.03908 6.79452 5.69086 5.09538C5.69086 5.09538 6.69834 4.76533 8.99149 6.35649C10.9584 5.80501 13.0337 5.80501 15.0006 6.35649C17.2917 4.76533 18.2972 5.09538 18.2972 5.09538C18.951 6.79044 18.5397 8.04137 18.4164 8.35512C19.229 9.25275 19.6725 10.4372 19.6544 11.6617C19.6544 16.3863 16.8466 17.4274 14.1759 17.7309C14.6051 18.114 14.9886 18.8617 14.9886 20.0107C14.9886 21.6569 14.9747 22.9811 14.9747 23.3866C14.9747 23.7146 15.1913 24.0976 15.7994 23.9774C18.5019 23.0521 20.7952 21.1714 22.2691 18.6715C23.7431 16.1716 24.3015 13.2156 23.8446 10.3322C23.3878 7.44886 21.9453 4.82617 19.7753 2.93323C17.6052 1.04029 14.8492 0.000558055 12 0H11.996Z" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/in/jessarqais"
            target="blank"
            aria-label="Jessars's linkedin profile"
          >
            <SvgWrapper>
              <InlineSvg>
                <path d="M5.56161 2.73983C5.52952 1.18458 4.48805 0 2.79685 0C1.10565 0 0 1.18458 0 2.73983C0 4.26287 1.07297 5.48156 2.73267 5.48156H2.76427C4.48805 5.48156 5.56161 4.26287 5.56161 2.73983ZM5.23597 7.64654H0.292297V23.5085H5.23597V7.64654ZM18.308 7.76514C21.5611 7.76514 24 10.0327 24 14.9048L23.9998 23.9996H19.0563V15.5134C19.0563 13.3819 18.341 11.9274 16.5513 11.9274C15.1855 11.9274 14.372 12.9079 14.0147 13.855C13.884 14.1944 13.8519 14.6673 13.8519 15.1414V24H8.90765C8.90765 24 8.97281 9.62644 8.90765 8.13803H13.8519V10.3847C14.5081 9.30471 15.6832 7.76514 18.308 7.76514Z" />
              </InlineSvg>
            </SvgWrapper>
          </SocialLink>
        </SocialLinks>
        {!props.article && (
          <Tooltip visible={tooltipIsVisible} article={props.article}>
            <TooltipText>{tooltipText}</TooltipText>
            <TooltipIcon>
              <Icon glyph="arrow" size={24} />
            </TooltipIcon>
          </Tooltip>
        )}
      </div>

    </Div>
  )
}

export default Header
